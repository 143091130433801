import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import { callFetchPaymentOptions, callFetchPaymentOptionsSuccess, callMPESAEnquiryApi, callMPESAValidationApi, callConvertCurrency } from "../../slices/paymentSlice";

/**
* fetch payment options
 */
export function* fetchPaymentOptionsSaga(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    let response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.paymentOptions,
      data: payload.data
    });
    if (response?.data?.statusCode === 200 && !!response?.data?.result?.response) {
      yield put(callFetchPaymentOptionsSuccess({
        data: response.data.result.response,
        transactionType: payload.data.transactionType
      }));
      payload?.successCallback();
    }
    else {
      payload?.errorCallback();
    }
  } catch (error) {
    payload?.errorCallback();
  }
}

export function* validateMPESASaga(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    let response: any = yield api.request({
      method: process.env.REACT_APP_VALIDATE_MPESA_USER_MOCK ? "GET" : "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data
    });
    if (response?.data?.statusCode === 200) {
      payload?.callback?.();
    }
    else {
      payload?.errorCallback();
    }
  } catch (error) {
    payload?.errorCallback();
  }
}

export function* enquireMPESA(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    let response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.recentContacts(),
      data: payload.data,
      hideLoader: payload.hideLoader
    });
    if (response?.data?.statusCode === 200) {
      payload?.successCallback?.(response?.data?.result?.data?.[0]?.statusMessage);
    }
    else {
      payload?.errorCallback(response?.data);
    }
  } catch (error) {
    payload?.errorCallback();
  }
}

export function* convertCurrency(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    let response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.cucurrencyConverter,
      data: payload.data,
    });
    if (response?.data?.statusCode === 200) {
      payload?.successCallback?.(response?.data?.result);
    }
    else {
      payload?.errorCallback(response?.data);
    }
  } catch (error) {
    payload?.errorCallback();
  }
}

export default function* root(): Generator {
  yield takeLatest(callFetchPaymentOptions.type, fetchPaymentOptionsSaga);
  yield takeLatest(callMPESAValidationApi.type, validateMPESASaga);
  yield takeEvery(callMPESAEnquiryApi.type, enquireMPESA);
  yield takeEvery(callConvertCurrency.type, convertCurrency)
}
