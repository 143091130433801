import { getIsGuestUser } from "../utils/commonUtils";

export const BASE_API_URL = "/api/b2c-backend";
export const baseName = "";

export const ENDPOINTS = {
  LOGIN: {
    sendOtp: () => getIsGuestUser() ? `/v3/send-otp` : `/v2/send-otp`,
    resendOTP: () => getIsGuestUser() ? `/v3/resend-otp` : `/v2/send-otp`,
    resendOtp: () => `/v1/resend-otp`,
    verifyOtp: () => `/v2/verify-otp`,
    secureSmsToken: () => `/v1/onboarding/get-sms-token`,
    secureSmsVerifyToken: () => `/v1/onboarding/verify-token-status`
  },
  BALANCE: {
    //checkAMBalance: () => `/v1/am/balance`,
    checkAMBalance: () => `/v2/am/balance`,
    checkGSMBalance: () => `/v1/gsm/balance`,
  },

  processTxn: () => `/v1/process-transaction`,
  processTxnV2: () => `v2/process-transaction`,
  processTxnV3: `v3/process-transaction`,
  processTxnV4: `v4/process-transaction`,
  txnFee: () => `/v1/transaction/fee`,
  lob: "/v1/check-lob",
  getMPESAProcessTxn: (paymentMode: string) => getIsGuestUser() ? ENDPOINTS.processTxnV4 : paymentMode === "MPESA" ? ENDPOINTS.processTxnV3 : ENDPOINTS.processTxnV2(),
  AM: {
    validatePin: () => `/v1/am/validate-pin`,
    isSufficientBal: () => 'v1/am/wallet-balance-sufficient',
    transactionHistory: () => '/v2/transaction-history?page=0&size=1&sort=string',
    setPin: () => `/v1/am/user/set-pin`,
    setQuestion: () => `/v1/am/set-security-question`,
    getQuestions: () => `/v1/am/security-questions`,
    changeAmPin: () => '/v1/am/user/change-pin',
    getCurrentQuestion: () => `/v1/am/security-question`,
    validateSecurityAns: () => `/v1/am/validate-security-answer`,
    validateDocId: () => `/v1/am/validate-document-id`,
    forgotPin: () => `/v1/am/user/forget-pin`,
    unlockPin: () => `/v1/am/user/unlock-pin`
  },

  HOME: {
    getUserInfo: () => `/v1/user-info`,
    getAMProfile: () => getIsGuestUser() ? `/v2/am/user` : `/v1/am/user`,
    getGuestAMProfile: () => `/v2/am/user`,
    captureIncentive: () => `/v1/capture-incentive`,
    setUserConfig: () => `/v1/set-user-config`,
  },
  CONFIG: {
    fetchConfig: (source: string) =>
      `/v1/config?config-code=${source}`,
    launchConfig: "v1/launch-config"
  },
  recentContacts: () => getIsGuestUser() ? "v1/poll-transaction-status" : "/v1/transaction-history",
  BUNDLE: {
    fetchBundles: () => `/v1/get-bundles`
  },
  FAVOURITES: {
    fetchFavourites: `/v1/favourites-by-category`,
    addFavourites: `/v1/favourite/add`,
    fetchAllFavourites: `/v1/favourites`,
    deleteFavourites: `/v1/favourite/delete`,
    editFavourites: `/v1/favourite/update`
  },
  betaFeatures: 'v1/features-available',
  betaUsers: 'v1/beta-users',
  getTranslations: 'v1/translation',
  PAY_BILL: {
    fetchCategories: "/v1/biller/categories",
    fetchBillers: (categoryCode: string = "") => `/v1/biller/category/${categoryCode}`,
    fetchBillerInfo: (billerCode: string = "") => `/v1/biller/${billerCode}`,
    customerLookup: (billerCode: string = "") => `/v1/biller/lookup/${billerCode}`,
    processTrxn: `v1/biller/process-transaction`
  },
  CMS: "/v1/content",
  LOGOUT: "/v1/logout",
  SEND_MONEY: {
    getOperatorData: `/v1/operator-config/operators`,
    getNumberOperator: `/v1/operator`,
  },
  WITHDRAW_CASH: {
    getAgentInfo: ''
  },
  paymentOptions: "v1/payment-options",
  transactionFeeV2: "v2/transaction/fee",
  checkTransactionLimit: 'v1/transaction-amount-validation',
  pollStatus: 'v1/poll-transaction-status',
  sendOtp: '/v3/send-otp',
  resendOtp: '/v3/resend-otp',
  cucurrencyConverter: '/v1/cur-conversion'
};


export const PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=com.airtel.africa.selfcare&hl=en`;
export const APP_STORE_URL = `https://apps.apple.com/us/app/my-airtel-africa/id1462268018`;
