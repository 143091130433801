import moment from "moment";
import { store } from "../Redux";
import { resetCheckLobData, resetHistoryData, resetRecentNumberName, saveAthsEvent, setApiError, setLoader } from "../Redux/slices/globalSlice";
import * as codes from "../config/codes";
import { HOME_PAGE, SEND_MONEY } from "../routes/routeConstants";
import _, { isBoolean } from "lodash";
import { BUY_BUNDLE, CASHOUT, P2P, P2PI, PREPAID_BUY_BUNDLES, PREPAID_RECHARGE } from "../data/constants";
import { callProcessTxnV2 } from "../Redux/slices/globalSlice";


export const getVersionDefaultPath = () => {
  return HOME_PAGE;
};

export const getLoaderRunningStatus = () => {
  let globalStore = store.getState()?.globalStore || {};
  return globalStore.isLoading;
};

export const startLoader = () => {
  store.dispatch(setLoader(true));
};

export const stopLoader = () => {
  store.dispatch(setLoader(false));
};

export const storeAthsEvent = (event: any) => {
  store.dispatch(saveAthsEvent(event));
};

export const getAthsEvent = () => {
  return store.getState().globalStore.athsEvent;
};

export const getTime = (date: Date) => {
  console.log(moment().format("DD/MM/YYYY, h:mm a"));
  return moment(date).format("DD/MM/YYYY, h:mm a");
};

// get error message for an api code
export const getErrorMsg = (
  code: string,
  isAMBarred: boolean | undefined
): string => {
  if (
    [
      codes.featureNames.RECHARGE_AIRTIME,
      codes.featureNames.SEND_MONEY,
      codes.balanceState.AIRTEL_MONEY_PIN,
    ].includes(code) &&
    Boolean(isAMBarred)
  ) {
    return "AM_BARRED";
  } else return "";
};


export const getOnlineStatus = () => {
  return store.getState()?.globalStore?.isOnline;
}

//Dvice/OS/Browser Detect
export const getDeviceInfo = () => {
  const windowObj: any = window;
  let module = {
    options: [],
    header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, windowObj.opera],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' }
    ],
    databrowser: [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ],
    init: function () {
      let agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);
      return { os: os, browser: browser };
    },
    matchItem: function (string: string, data: any) {
      let i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches: any,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: 'unknown', version: 0 };
    }
  };

  let e = module.init();
  return {
    "OSName": e.os.name,
    "OSVersion": e.os.version,
    "BrowserName": e.browser.name,
    "BrowserVersion": e.browser.version,
    "userAgent": navigator.userAgent,
    "platform": navigator.platform,
  };
}

export const checkIsOnline = () => {
  const isOnline = store.getState()?.globalStore?.isOnline || false;
  return isOnline;
}

//This function check the time diffrence and if time difference is less than 4 hours it gives false
export const checkValidTimeDifference = (timeStamp: string = "", timeout: number = 15) => {
  if (timeStamp) {
    let now = moment(new Date()), //todays date
      end = moment(timeStamp), // another date
      duration = moment.duration(now.diff(end)),
      minutes = duration.minutes();
    return (minutes >= timeout);
  }
  return true;
}

export const resetData = () => {
  store.dispatch(resetCheckLobData({}));
  store.dispatch(resetHistoryData({}));
  store.dispatch(resetRecentNumberName({}));
}

export const checkValidValue = (regex: RegExp, value: any) => {
  if (regex) {
    regex = new RegExp(regex);
    return regex?.test(value);
  }
  return true;
}

export const filterDataByKey = (data: any = [], key: string = "") => {
  let result = [];
  result = _.orderBy(data, [(obj) => obj[key] || ''], ['desc']);
  return result;
}

export function addSpaceInMsisdn(msisdn: string = "") {
  // Use a regular expression to match the first three letters
  const regex = /^(.{3})/

  // Insert a space after the first three letters
  const resultString = msisdn.replace(regex, '$1 ');

  return resultString;
}

export function isPinset() {
  let amProfile: any = store.getState().home.amProfile,
    flag = !amProfile?.pinSet || amProfile.pinSet === 'true';
  return flag;
}

export function isQuestionSet() {
  let amProfile: any = store.getState().home.amProfile,
    flag = amProfile?.recoveryQuestionSet == 'true';
  return flag;
}

export function isResetPinEnable() {
  let amProfile: any = store.getState().home.amProfile,
    flag = (amProfile.pinReset === 'true' && amProfile.pinSet === 'true');
  return flag;
}

export function isResetPin() {
  let amProfile: any = store.getState().home.amProfile,
    flag = isBoolean(amProfile?.pinReset) ? amProfile?.pinReset : amProfile.pinReset === 'true';
  return flag;
}

export function isAMBarred() {
  let amProfile: any = store.getState().home.amProfile,
    flag = amProfile?.userBarred === 'true' || amProfile?.userBarred === true;
  return flag;
}

export function isAmAccountActive() {
  const amAccountActive: any = store.getState().home.amProfile?.accountStatus?.isActive;
  return amAccountActive === false ? amAccountActive : true;
}

export function isTransactionLimitAllow(type: string) {
  const transactionLimtConfig = store.getState().config.launchConfig.transactionLimConfig;
  let status = false;
  if (transactionLimtConfig?.IS_ENABLED) {
    switch (type) {
      case PREPAID_BUY_BUNDLES:
        status = transactionLimtConfig?.PREPAID_BUY_BUNDLES ?? false;
        break;
      case PREPAID_RECHARGE:
        status = transactionLimtConfig?.PREPAID_RECHARGE ?? false;
        break;
      case P2P:
        status = transactionLimtConfig?.P2P ?? false;
        break;
      case P2PI:
        status = transactionLimtConfig?.P2PI ?? false;
        break;
      case CASHOUT:
        status = transactionLimtConfig?.CASHOUT ?? false;
        break;
      default: break;
    }
  }
  return status
}

export const isArray = (value: any) => {
  return Object.prototype.toString.call(value) === "[object Array]";
}

export function handleMsisdnPaste(e: React.ClipboardEvent<HTMLInputElement>, handleFunc: any) {
  let msisdn = "";
  const msisdnLength = store.getState().config?.basicConfig?.msisdnLength;
  const pastedData = e.clipboardData
    .getData('text/plain')
    .trim();
  msisdn = pastedData?.replaceAll(/\s/g, ''); //remove whitespace
  const length = Math.max(msisdn.length - +msisdnLength);
  msisdn = msisdn.substring(length);
  handleFunc(msisdn);
}

export const isLockedUser = () => {
  const lockInfo: any = store.getState().home.amProfile?.lockInfo;
  let isLocked = false;
  if (lockInfo?.isLocked) {
    if (lockInfo?.lockedBy === 'INVALID_DOCUMENT_ID') {
      if (new Date().getTime() < new Date(lockInfo?.lockExpiry).getTime()) isLocked = true
    } else if (lockInfo?.lockedBy === 'INVALID_SECURITY_ANSWER') isLocked = true
  }
  return isLocked;
}

export const setApiErrorStatus = (flag: boolean = false, errorBy: string = "") => {
  store.dispatch(setApiError({ isApiError: flag, errorBy }));
}