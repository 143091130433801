import { store } from "../Redux";
import { routesEventMap } from "../routes/routesEventMap";
import { getAuthTokenFromLs, getIsGuestUser, getPhoneNumberFromLs } from "../utils/commonUtils";

const eventTracker = (window as any)?.eventTrackerInit();

export const eventTrackerInitialize = () => {
  const channel = "AppLite";
  const xCountry = process.env.NODE_ENV === "production" ? process.env.REACT_APP_OPCO : process.env.REACT_APP_X_COUNTRY || "";

  if (store.getState().config.launchConfig.eventsEnabled && eventTracker && channel && xCountry) {
    eventTracker?.initialize({
        eventBaseURL: "/api/b2c-backend",
        channel: channel,
        xCountry: xCountry || "",
        msisdn: getPhoneNumberFromLs(),
        header: {}
    });
  }
}

export const sendPageViewEvent = () => {
  if (eventTracker && store.getState().config.launchConfig.eventsEnabled && !getIsGuestUser()) {
    const date = new Date();
    eventTracker.send({
      eventType: "page_view",
      token: getAuthTokenFromLs(),
      timeStamp: date.toISOString(),
      msisdn: getPhoneNumberFromLs(),
      action: `${routesEventMap[window?.location?.pathname]}_page_view`
    }); 
  }
}

export const sendEvent = ({ eventType, ...rest }: SendEvent) => {
  if (eventTracker && store.getState().config.launchConfig.eventsEnabled && !getIsGuestUser()) {    
    const date = new Date();
    eventTracker.send({
      eventType: eventType,
      token: getAuthTokenFromLs(),
      timeStamp: date.toISOString(),
      msisdn: getPhoneNumberFromLs(),
      ...rest
    });
}
}

interface SendEvent {
    eventType: string;
    [key: string]: string | number | boolean | object | null | undefined;
}
