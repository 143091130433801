import { Route, Routes, useLocation } from "react-router-dom";
import { ReactElement, Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../Redux";
import { featureNames } from "../config/codes";
import { ACCESS_TYPES, DISMISSED, V2 } from "../data/constants";
import { isFeatureAvailableInStore } from "../features/beta/helpers/betaHelper";
import { storeAthsEvent } from "../helpers/commonHelpers";
import { ga4Initialize, sendGA4PageView } from "../services/ga4";
import * as routes from "./routeConstants";
import { buyBundle } from "./routeConstants";
import { getSkeletonBlock } from "./routeSkeletons"
import { Skeleton } from "../components/SkeletonCard";
import ActivePacks from "../features/BalanceV2/components/ActivePacks/ActivePacks";
import IncompatiblePage from "../pages/IncompatiblePage";
import SetQuestionPage from "../pages/SetQuestion";
import TransactionDetailsPage from "../pages/TransactionDetailsPage";
import { AuthRoute, getDefaultElement } from "./authRoute";
import { sendPageViewEvent } from "../services/eventService";

const Login = lazy(() => import('../pages/Login')),
  LogOut = lazy(() => import('../pages/LogOut')),
  DashboardPage = lazy(() => import('../pages/Dashboard/DashboardPage')),
  HomePage = lazy(() => import('../pages/HomePage')),
  Balance = lazy(() => import('../pages/Balance')),
  Bundle = lazy(() => import('../pages/Bundle')),
  AMPinError = lazy(() => import('../pages/AMPinError')),
  SendMoney = lazy(() => import('../pages/SendMoney')),
  Success = lazy(() => import('../pages/Success')),
  BuyAirtimePageV2 = lazy(() => import('../pages/BuyAirtimePageV2/BuyAirtimePageV2')),
  Recharge = lazy(() => import('../pages/Recharge')),
  UssdPage = lazy(() => import('../pages/UssdPage')),
  Favourite = lazy(() => import('../pages/Favourite')),
  AppSettingsPage = lazy(() => import('../pages/AppSettingsPage/AppSettingsPage')),
  HelpAndSupportPage = lazy(() => import('../pages/HelpAndSupportPage/HelpAndSupportPage')),
  AMTransactionHistory = lazy(() => import('../pages/AMTransactionHistory')),
  PaymentMethodPage = lazy(() => import('../pages/PaymentMethodPage/PaymentMethodPage')),
  SuccessV2 = lazy(() => import('../pages/SuccessV2/SuccessV2')),
  WithDrawCashPage = lazy(() => import('../pages/WithDrawCash')),
  FailurePageV2 = lazy(() => import('../pages/FailurePageV2/FailurePageV2')),
  StaticAppSettingsPages = lazy(() => import('../pages/StaticAppSettingsPage/StaticAppSettingsPage')),
  MyFavourite = lazy(() => import('../pages/MyFavourite')),
  PayBill = lazy(() => import('../pages/PayBill')),
  InProgressPage = lazy(() => import("../pages/InProgressPage/InProgressPage")),
  BuyGoodsPage = lazy(() => import("../pages/BuyGoodsPage")),
  BuyBundlePagev2 = lazy(() => import("../pages/BuyBundlePageV2")),
  LanguagePage = lazy(() => import('../pages/LanguagePage/LanguagePage')),
  HEError = lazy(() => import("../pages/HEError")),
  HELogin = lazy(() => import("../pages/HELogin")),
  ChangePin = lazy(() => import("../pages/ChangePin")),
  AMForgotPinPage = lazy(() => import("../pages/AMForgotPinPage/AMForgotPinPage"));
interface IRoute {
  path: string;
  element: ReactElement;
  access: string | string[];
}

const AppRoutes = () => {
  const allBetaFeatures = useSelector((state: RootState) => state.config?.launchConfig?.allBetaFeatureCodes);
  const version = useSelector((state: RootState) => state.config?.launchConfig?.version);

  const location = useLocation();

  useEffect(() => {
    let ele = document.getElementById("add-to-homescreen");
    if ("onbeforeinstallprompt" in window) {
      window.addEventListener("beforeinstallprompt", beforeInstallPrompt);
    }
    function beforeInstallPrompt(evt: any) {
      evt.preventDefault();
      storeAthsEvent(evt);
      if (ele) {
        ele.addEventListener('click', async function () {
          evt?.prompt();
          const { outcome } = await evt.userChoice;
          console.log("user choice", outcome);
          if (outcome === DISMISSED)
            window.location.reload();
          storeAthsEvent({});
        });
      }
    }
    if (process.env.NODE_ENV === "production" && store.getState().config?.launchConfig?.disableRightClick) {
      window.oncontextmenu = function () {
        return false;
      }
    }
    ga4Initialize();
    return () => {
      if (ele)
        ele.removeEventListener("click", function () { });
      window.removeEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        // storeAthsEvent({});
        storeAthsEvent({});
        e.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    sendGA4PageView();
    sendPageViewEvent();    
  }, [location.pathname]);

  // List of routes in objects format with there path, mapped element, access info.
  const element = [
    {
      path: "/",
      element: <HELogin />,
      access: ACCESS_TYPES.PUBLIC,
    },
    {
      path: routes.LOGIN_ROUTE,
      element: <Login />,
      access: ACCESS_TYPES.PUBLIC,
    },
    {
      path: routes.LOG_OUT,
      element: <LogOut />,
      access: ACCESS_TYPES.PUBLIC,
    },
    {
      path: routes.IN_COMPATIBLE,
      element: <IncompatiblePage />,
      access: ACCESS_TYPES.PUBLIC,
    },
    {
      path: routes.HE_Error,
      element: <HEError />,
      access: ACCESS_TYPES.PUBLIC,
    },
    {
      path: routes.HOME_PAGE,
      element: version === V2 ? <DashboardPage /> : <HomePage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: buyBundle.BUNDLE_MENU,
      element: version === V2 ? <BuyBundlePagev2 /> : <Bundle />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.SUCCESS,
      element: <Success />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.recharge.RECHARGE,
      element: version === V2 ? <BuyAirtimePageV2 /> : <Recharge />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.APP_SETTINGS_ROUTE,
      element: <AppSettingsPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.HELP_AND_SUPPORT_ROUTE,
      element: <HelpAndSupportPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.PAYMENT_METHODS_ROUTE,
      element: <PaymentMethodPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.SUCCESS_V2,
      element: <SuccessV2 />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.FAILED_V2,
      element: <FailurePageV2 />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.STATIC_APP_SETTINGS_ROUTE,
      element: <StaticAppSettingsPages />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.IN_PROGRESS_ROUTE,
      element: <InProgressPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.balanceRoute.BALANCE,
      element: <Balance />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.AM_PIN_ERROR_RT,
      element: <AMPinError />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.SEND_MONEY,
      element: <SendMoney />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.USSD_ROUTE,
      element: <UssdPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.PAY_BILL,
      element: <PayBill />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.MY_FAV,
      element: <MyFavourite />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.airtelMoney.TRANSACTION_HISTORY,
      element: <AMTransactionHistory />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.WITHDRAW_CASH,
      element: <WithDrawCashPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.BUY_GOODS_RT,
      element: <BuyGoodsPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.balanceRoute.ACTIVE_PACKS,
      element: <ActivePacks />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.airtelMoney.SET_QUESTION,
      element: <SetQuestionPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.LANGUAGE_ROUTE,
      element: <LanguagePage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.TRANSACTION_DETAILS_ROUTE,
      element: <TransactionDetailsPage />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.CHANGE_PIN_ROUTE,
      element: <ChangePin />,
      access: ACCESS_TYPES.PROTECTED,
    },
    {
      path: routes.airtelMoney.FORGOT_PIN,
      element: <AMForgotPinPage />,
      access: ACCESS_TYPES.PROTECTED
    }
  ];

  // Function to return the list of routes available for user to navigate.
  const getRouteList = () => {
    return element?.map((route: IRoute) => {
      const { path, element, access } = route;
      return (
        <Route key={path} path={path} element={<AuthRoute accessType={access}>{element}</AuthRoute>} />
      );
    })
  }

  return <Suspense fallback={<Skeleton {...getSkeletonBlock()} />}>
    <Routes>
      {getRouteList()}
      <Route path="*" element={getDefaultElement()} />
    </Routes>
  </Suspense>
};

export default AppRoutes;