import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import { AM_DOWN_CODE, errorCodes, AM_BARRED_CODE } from "../../../data/constants";
import api from "../../../services/apiService";
import * as balanceSlice from "../../slices/balanceSlice";
import { callCheckAMBalance, callCheckGSMBalance, callValidateAMBalancePin } from "../../slices/balanceSlice";
import { sendGA4Event } from "../../../services/ga4";
import { getPhoneNumberFromLs } from "../../../utils/commonUtils";
import { getText } from "../../../helpers/translateHelper";

function* checkGSMBalance(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response = (yield api.request({
      method: "POST",
      url: ENDPOINTS.BALANCE.checkGSMBalance(),
      data: payload.data,
      hideLoader: payload?.hideLoader,
    })) as AxiosResponse;

    if (response && response?.data?.statusCode === 200) {
      yield put(balanceSlice.callCheckGSMBalanceSuccess(response));
      !!action?.payload?.callback && action.payload.callback(response);
    } else {
      if (window.location?.pathname?.includes('balance') && !!response.data?.message)
        ToastNotifyError(response.data.message);
      yield put(balanceSlice.callCheckGSMBalanceFailure(response));
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    yield put(balanceSlice.callCheckGSMBalanceFailure(error));
  }
}

function* checkAMBalance(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.BALANCE.checkAMBalance(),
      data: payload.data,
    });
    if (response) {
      yield put(balanceSlice.callCheckAMBalanceSuccess(response));
      !!action?.payload?.callback && action.payload.callback(response);
    } else {
      !!response.data?.message && ToastNotifyError(response.data.message);
      yield put(balanceSlice.callCheckAMBalanceFailure(response));
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    yield put(balanceSlice.callCheckAMBalanceFailure(error));
  }
}

function* validateAMBalancePin(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response = (yield api.request({
      method: "POST",
      url: ENDPOINTS.AM.validatePin(),
      data: payload.data,
    })) as AxiosResponse;

    if (response && response?.data?.statusCode === 200) {
      yield put(balanceSlice.callValidateAMBalancePinSuccess(response));
      !!action?.payload?.successCallback && action.payload.successCallback();
    } else if (response?.data?.statusCode === AM_DOWN_CODE && response?.status === 200) {
      ToastNotifyError(getText("AM_SERVICE_DOWN"));
      action?.payload?.errorCallback?.(response?.data?.message);
    }
    else if (errorCodes.PROCESS_TXN_AM_PIN_NEW_ERROR_PAGE_CODES.includes(response?.data?.statusCode)) {
      yield put(balanceSlice.callValidateAMBalancePinFailure(response));
      if (AM_BARRED_CODE.includes(response?.data?.statusCode)) {
        sendGA4Event({ category: "pinlocked", action: `pinlocked_incorrects_attempts` });
      }
      action?.payload?.errorPageCallback?.(response?.data?.message);
    }
    else {
      yield put(balanceSlice.callValidateAMBalancePinFailure(response));
      action?.payload?.errorCallback?.(response?.data?.message);
    }
    if (response?.data?.statusCode === 4177) {
      sendGA4Event({ category: "am", action: `invalid_am_pin` });
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    yield put(balanceSlice.callValidateAMBalancePinFailure(error));
  }
}

export default function* root(): Generator {
  yield takeLatest(callCheckGSMBalance.type, checkGSMBalance);
  yield takeLatest(callCheckAMBalance.type, checkAMBalance);
  yield takeLatest(callValidateAMBalancePin.type, validateAMBalancePin);
}
